import { render, staticRenderFns } from "./listC.vue?vue&type=template&id=379782b8&scoped=true&"
import script from "./listC.vue?vue&type=script&lang=js&"
export * from "./listC.vue?vue&type=script&lang=js&"
import style0 from "./listC.vue?vue&type=style&index=0&id=379782b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379782b8",
  null
  
)

export default component.exports
<!--
 * @Author: 张博洋
 * @Date: 2021-11-16 15:35:39
 * @LastEditTime: 2021-11-16 18:40:30
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/views/patient/components/listC.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
<div class="wrap">
    <van-pull-refresh v-model="refreshing"
                      @refresh="onRefresh">
      <van-list  v-if="list.length" v-model="loading"
                :finished="finished"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                @load="getData">
            <div class="step">
            <div class="step-item"
                v-for="(item,index) in list"
                :key="index">
                <div class="icon"
                    :class="index === 0 ? 'bg2': 'bg1'">
                </div>

                <div class="con ml12 flex1">
                <div class="top"
                    @click="checkResult(item)">
                    <div>
                    <div class="type"
                        :class="item.questionnaireId === 1 ? 'type1': 'type2'">{{item.questionnaireId === 1 ?  '焦虑自评' : '抑郁自评'}}</div>
                    <div class="time ml12">{{$.replyTime(item.createTime)}}</div>
                    </div>
                    <van-icon name="arrow"
                            color="#999" />
                </div>
                <div class="bottom mt14">
                    <p>结果：{{item.result}}</p>
                    <p>分析：{{item.detail}}</p>
                </div>
                </div>
            </div>
            
            </div>
      </van-list>
      <div v-if="!list.length && empty" class="empty full-h">
                <img width="80"
                    src="../../../static/img/empty-record.png">
                <p class="mt20">暂无记录</p>
            </div>
   </van-pull-refresh>
</div>
   
</template>


<script>
export default {
  data () {
    return {
      list: [],
      query: {
        pageNo: 0,
        pageSize: 10,
        patientId: this.$route.query.id
      },
      loading: false,
      finished: false,
      refreshing: false,
      empty: false,
      error: false,
    }
  },
  created () {
      this.onRefresh()
  },
  computed: {

  },
  methods: {
      getData() {
        this.empty = false
        this.loading = true
        this.query.pageNo ++
        setTimeout(() => {
            this.$axios({
            type: 'post',
            url: 'question/queryQAHistory',
            data: this.query,
            elseData: {
                ajaxLoading: true,
                errorTip: false,
            },
            })
            .then((res) => {
                console.log(res)
                res.d.data || (res.d.data = [])
                this.list = [...this.list, ...res.d.data]

                this.loading = false
                // 数据全部加载完成
                if (this.list.length >= res.d.total) {
                this.finished = true
                }
                if (!res.d.data.length) {
                this.empty = true
                }
            })
            .catch((e) => {
                this.error = true
            })
        }, 100)
    },
    onRefresh() {
      // 清空列表数据
      this.empty = false
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.query.pageNo = 1
      setTimeout(() => {
        this.$axios({
          type: 'post',
          url: 'question/queryQAHistory',
          data: this.query,
          elseData: {
            ajaxLoading: true,
            errorTip: false,
          },
        })
          .then((res) => {
            console.log(res)
            res.d.data || (res.d.data = [])
            this.list = [...res.d.data]
            this.loading = false
            this.refreshing = false
            // 数据全部加载完成
            if (this.list.length >= res.d.total) {
              this.finished = true
            }
            if (!res.d.data.length) {
              this.empty = true
            }
          })
          .catch(() => {
            this.error = true
            this.loading = false
          })
      }, 100)
    },
    // // 获取患者服务记录列表
    // getQuestionHistory () {
    //   this.$axios({
    //     type: 'post',
    //     url: 'question/queryQAHistory',
    //     data: this.query,
    //     elseData: {
    //       ajaxLoading: true,
    //       errorTip: false,
    //     },
    //     _self: this,
    //   }).then(res => {
    //     console.log(res)
    //     res.d || (res.d = []);
    //     this.list = [...this.list, ...res.d.data];
    //     this.total = res.total
    //     //回调内置方法；
    //     this.$infinite.ajaxThen(res);
    //   }).catch(e => {
    //     this.$infinite.ajaxError(e);
    //   })
    // },
    checkResult (item) {
      this.$router.push({
        name: 'check.result',
        query: {
          questionUserRefId: item.questionUserRefId,
          questionnaireId: item.questionnaireId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
    overflow: auto;
    height: calc(100vh - 50px);
}
.step {
  display: flex;
  flex-direction: column;
  margin: 8px 8px 16px 8px;
  border-radius: 8px;
  padding: 16px 16px 20px 16px;
  background: #fff;
  flex: 1;
  
  .step-item {
    width: 100%;
    display: flex;
    padding-bottom: 35px;
    .icon {
      width: 28px;
      height: 28px;
      position: relative;
      background-size: 100%;
    }
    .bg1 {
      background: url('../../../static/img/step.png');
      background-size: 100%;
    }
    .bg2 {
      background: url('../../../static/img/step-active2.png');
      background-size: 100%;
    }
    .icon:after {
      content: '';
      z-index: 8;
      position: absolute;
      left: 14px;
      top: 14px;
      min-height: 127px;
      max-height: 133px;
      width: 1px;
      border-left: 1px dashed #e5e5e5;
    }
    .con {
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
          display: flex;
          align-items: center;
        }
        .type {
          border-radius: 4px;
          line-height: 20px;
          text-align: center;
          padding: 2px 6px;
        }
        .type1 {
          background: rgba(129, 105, 216, 0.1);
          color: rgba(129, 105, 216, 1);
        }
        .type2 {
          background: rgba(250, 173, 20, 0.1);
          color: rgba(250, 173, 20, 1);
        }
        .time {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
      .bottom {
        background: #f5f5f5;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        p {
          font-size: 14px;
          overflow: hidden;
          margin: 8px 8px;
          text-overflow: ellipsis;
          display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
          -webkit-line-clamp: 1; /* 控制最多显示几行 */
          -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
          text-align: left;
        }
      }
    }
  }
  .step-item:last-of-type {
      .icon::after {
        content: '';
        z-index: 8;
        position: absolute;
        left: 14px;
        top: 14px;
        min-height: 100px;
        max-height: 133px;
        width: 1px;
        border-left: 1px dashed #e5e5e5;
    }
  }
}
.empty {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: #999;
    p {
      font-size: 14px;
    }
    img {
      margin-top: calc(50% - 80px);
    }
    color: #999;
  }
</style>

<template>
  <div class="wrapper">
    <div class="main">
        <van-tabs v-model="activeName"
                    :ellipsis="false"
                    swipeable>
         <van-tab title="健康档案"
                   name="a">
            <div class="con" v-if="detailsData.editDoc === 1">
                 <div class="info p mt8">
                    <van-cell title="姓名"
                            :value="detailsData.patientName" />
                    <van-cell title="性别"
                            :value="sex" />
                    <van-cell title="年龄"
                            :value="detailsData.age ? detailsData.age + '岁' : ''" />
                    <van-cell title="联系方式"
                            :value="detailsData.phone" />
                    <van-cell title="所在地区"
                            :value="detailsData.address" />
                </div>
                <div class="hsitory p mt8">
                    <h1>既往病史</h1>
                    <div class="tags">
                    <div class="tag"
                        v-for="(d,index) in diseaseList"
                        :key="index">{{d.disease.length > 8 ? d.disease.slice(0,8) + '...' : d.disease}}</div>
                    <div v-if="!diseaseList.length">暂无</div>
                    </div>
                </div>
                <archives :archivesData="archivesData"></archives>
            </div>
            <div v-else
                 class="flex flex-column"
                 style="text-align:center;padding: 30px 15px; flex:1">
              <img style="width:60px;  margin-top: calc(50% - 80px);"
                   src="@/static/img/empty-record.png"
                   alt="">
              <p class="block mt20"
                 style="color:#999; font-size:14px">暂无健康档案~</p>
              <van-button round
                          class="mt10 ml5 mr5 btn"
                          plain
                          type="primary"
                          @click="completeRecord">
                去完善健康档案
              </van-button>
            </div>
            <div class="btn-group"  v-if="detailsData.visitTaskFlag === 1">
                <van-button  color="#1AB370"
                            class="btn-order"
                            @click="checkFollow"
                            round>查看随访任务</van-button>
            </div>
          </van-tab>
          <van-tab title="服务记录"
                   name="b">
            <ListS></ListS>
          </van-tab>
          <van-tab title="心理测评"
                   name="c">
            <ListC></ListC>
          </van-tab>
        </van-tabs>
    </div>


  </div>
</template>

<script>
import Tab from '@/mixin/Tab.js'
import ListS from './components/listS.vue'
import ListC from './components/listC.vue'
import archives from '@/components/archives/detail'
export default {
  mixins: [Tab],
  data() {
    return {
      activeName: 'a',
      patientName: '',
      age: '',
      sex: '',
      phone: '',
      detailsData: {},
      titleList: [
        {
          label: '服务记录',
          name: '服务记录',
          value: 0,
        },
        {
          label: '健康记录',
          name: '健康记录',
          value: 1,
        },
      ],
      diseaseList: [],
      infiniteKey: 0, //重试使用
      archivesData:{}
    }
  },
  components: {
    ListS,
    ListC,
    archives
  },

  created() {
    this.getPatientDetail()
    this.getDiseaseHistory()
    this.getDoc()
  },
  activated() {
    this.getPatientDetail()
    this.getDiseaseHistory()
    this.getDoc()
  },
  methods: {
    // 查询详情
    getPatientDetail() {
      this.$axios({
        url: 'healthy/getPatientAndDocument',
        data: {
          id: this.$route.query.id,
        },
        elseData: {
            loading:true,
            ajaxLoading: true,
        },
        _self: this,
      }).then((res) => {
        this.$.setTitle(res.d.patientName)
        console.log(res)
        let { sex } = res.d
        this.detailsData = res.d
        this.sex = sex
        if (sex === 1) {
          this.sex = '男'
        } else if (sex === 2) {
          this.sex = '女'
        }
      })
    },
    // 获取既往病史
    getDiseaseHistory() {
      this.$axios({
        url: 'healthy/getDiseaseHistory',
        data: {
          id: this.$route.query.id,
        },
        elseData: {
          ajaxLoading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.diseaseList = res.d.data
      })
    },
    getDoc() {
        this.$axios({
            url: 'healthyDoc/queryHealthDoc',
            data: {
            patientId: this.$route.query.id,
            },
            elseData: {
            loading: true,
            },
        }).then((res) => {
            console.log(res)
            this.archivesData = res.d
        })
    },

    completeRecord() {
      this.$router.push({
        name: 'archives.edit',
        query: {
          patientId: this.$route.query.id,
        },
      })
    },
    checkFollow() {
      this.$router.push({
        name: 'follow.list',
        query: {
          patientId: this.detailsData.patientId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  overflow:hidden;
  display: flex;
  flex-direction: column;
  .main {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .van-tabs{
      flex: 1;
      height: 100%;
      overflow: hidden;
      display:flex;
      flex-direction: column;
      
    /deep/.van-tabs__content--animated {
        flex:1;
        overflow: auto;
     }
    /deep/.van-tab__pane {
        height: 100%;
        display: flex;
        flex-direction: column;
        }
   
    }
    /deep/.van-tabs__content {
        flex: 1;
        overflow: auto;
    }
     /deep/.van-tabs--line .van-tabs__wrap {
        min-height: 50px;
    }
  }
}
.p {
  margin: 0 8px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
}
.con {
    flex: 1;
    height: 100%;
    overflow: auto;
    margin-bottom: 8px;
    .info {
    .van-cell {
        padding-left: 0;
        padding-right: 0;
        .van-cell__title {
            color: #666;
            flex: none;
             width: 112px;
            flex: none;
        }
        .van-cell__value {
            text-align: left;
            word-wrap: unset;
            color: #333;
            flex: 1;
        }
    }
    .van-cell::after {
        left: 0;
        right: 0;
    }
    }
    .hsitory {
    padding-bottom: 16px;
    h1 {
        height: 49px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 49px;
    }
    .tags {
        display: flex;
        flex-wrap: wrap;
    }
    .tag {
        background: #f5f5f5;
        border-radius: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 6px 13px;
        margin: 6px 10px 0 0;
        text-align: center;
        font-size: 14px;
        color: #333333;
    }
    } 
}


.btn-group {
  width: 100%;
  background: #fff;
  padding: 10px 25px 30px 25px;
  margin-top: 8px;

  .van-button {
    width: 100%;
    margin: 0;
  }
}
// /deep/.van-list {
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     overflow: auto;
// }
</style>
